import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const ResultTable = () => {
  const [todayDate, setTodayDate] = useState(null);
  const [gamesToday, setGamesToday] = useState([]);
  const [gamesLast30Days, setGamesLast30Days] = useState({});
  const [showToday, setShowToday] = useState(true);
  const [showLast30Days, setShowLast30Days] = useState(false);
  const [loadingToday, setLoadingToday] = useState(true);
  const [loadingLast30Days, setLoadingLast30Days] = useState(false);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    setTodayDate(formatDate(currentDate));
  }, []);

  useEffect(() => {
    const fetchGameResults = async () => {
      try {
        const fetchCollectionResults = async (date) => {
          const querySnapshot = await getDocs(collection(db, date));
          if (querySnapshot.empty) {
            return [];
          }
          return querySnapshot.docs.map(doc => ({
            ...doc.data(),
            date: date,
            pattiResult: doc.data().result ?? '--',
            tripleResult: doc.data().singleResult ?? '--'
          })).sort((a, b) => parseInt(a.gameName) - parseInt(b.gameName));
        };

        if (todayDate) {
          const fetchedGamesToday = await fetchCollectionResults(todayDate);
          if (fetchedGamesToday.length > 0) {
            setGamesToday(fetchedGamesToday);
          }
          setLoadingToday(false);

          setLoadingLast30Days(true);
          const last30DaysResults = {};
          const currentDate = new Date();
          for (let i = 0; i < 30; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() - i);
            const formattedDate = formatDate(date);
            const fetchedGames = await fetchCollectionResults(formattedDate);
            if (fetchedGames.length > 0) {
              last30DaysResults[formattedDate] = fetchedGames;
            }
          }
          setGamesLast30Days(last30DaysResults);
          setLoadingLast30Days(false);
        }
      } catch (error) {
        console.error('Error fetching game results:', error);
      }
    };

    fetchGameResults();
  }, [todayDate]);

  const renderTableRows = (games, fatafatName) => {
    return Object.keys(games).map((date) => {
      const filteredGames = games[date].filter(game => game.fatafatName === fatafatName);
      return (
        filteredGames.length > 0 && (
          <tr key={date}>
            <td>{date}</td>
            {filteredGames.map((game, index) => (
              <td key={`${date}-${index}`}>
                {game.pattiResult}<br />{game.tripleResult}
              </td>
            ))}
          </tr>
        )
      );
    });
  };

  return (
    <>
      {loadingLast30Days ? (
        <div className='preloader'>
          <div className='preloader__container'>
            <div className='preloader__img-container'>
              <div className='preloader__item'></div>
              <div className='preloader__item preloader__item--mid'></div>
              <div className='preloader__item preloader__item--inner'></div>
              <div className='preloader__clock clock'>
                <i className='clock__center' />
                {[...Array(6)].map((_, i) => (
                  <div key={i} className='clock__dots' />
                ))}
              </div>
            </div>
            <h3 className='preloader__mesage'>Loading...</h3>
          </div>
        </div>
      ) : (
        <>

          <div className="accordion">
            <div className="accordion-item">
              <div className="accordion-title" onClick={() => setShowToday(!showToday)}>
                <h3>Today's Results</h3>
              </div>
              <div className="accordion-content">
                {loadingToday ? (
                  <div>Loading today's table...</div>
                ) : (
                  <>
                    <div className="table-wrapper">
                      <h3>Showing Result For {todayDate}</h3>
                      <table className="fl-table">
                        <thead>
                          <tr>
                            <th>Baziname</th>
                            <th>Bazi 1</th>
                            <th>Bazi 2</th>
                            <th>Bazi 3</th>
                            <th>Bazi 4</th>
                            <th>Bazi 5</th>
                            <th>Bazi 6</th>
                            <th>Bazi 7</th>
                            <th>Bazi 8</th>
                            <th>Bazi 9</th>
                            <th>Bazi 10</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* First row for Kolkata Bazi */}
                          <tr>
                            <td>Kolkata Bazi</td>
                            {gamesToday
                              .filter(game => game.fatafatName === 'kolkatafatafat') // Filter for Kolkata
                              .map((game, index) => (
                                <td key={index}>
                                  {game.pattiResult}<br />{game.tripleResult}
                                </td>
                              ))}
                          </tr>

                          {/* Second row for Kalyani Bazi */}
                          <tr>
                            <td>Kalyani Bazi</td>
                            {gamesToday
                              .filter(game => game.fatafatName === 'kalyanifatafat') // Filter for Kalyani
                              .map((game, index) => (
                                <td key={index}>
                                  {game.pattiResult}<br />{game.tripleResult}
                                </td>
                              ))}
                          </tr>
                        </tbody>

                      </table>
                      <h1 style={{ fontSize: ' 2rem ' }}> LAST 30 DAYS RESULT</h1>
                      <h3>Kolkata Fatafat Results</h3>
                      <table className="fl-table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Bazi 1</th>
                            <th>Bazi 2</th>
                            <th>Bazi 3</th>
                            <th>Bazi 4</th>
                            <th>Bazi 5</th>
                            <th>Bazi 6</th>
                            <th>Bazi 7</th>
                            <th>Bazi 8</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderTableRows(gamesLast30Days, 'kolkatafatafat')}
                        </tbody>
                      </table>
                    </div>

                    <div className="table-wrapper">
                      <h3>Kalyani Fatafat Results</h3>
                      <table className="fl-table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Bazi 1</th>
                            <th>Bazi 2</th>
                            <th>Bazi 3</th>
                            <th>Bazi 4</th>
                            <th>Bazi 5</th>
                            <th>Bazi 6</th>
                            <th>Bazi 7</th>
                            <th>Bazi 8</th>
                            <th>Bazi 9</th>
                            <th>Bazi 10</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderTableRows(gamesLast30Days, 'kalyanifatafat')}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ResultTable;
